//Header
header {
  width: 100%;
  background: #fff;
  z-index: 999;
  top: 40px;
  position: fixed;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.25);
  top: 0;
  background: #fff;

  @include mq(765) {
    height: 65px; }

  .header-content {
    @include clearfix;
    background: #fff;

    @include mq(1200) {
      padding: 0 30px; }

    @include mq(765) {
      padding: 0 15px; }

    .logo-holder {
      float: left;
      margin-top: 9px;
      margin-left: 50px;
      margin-right: 200px;

      @include mq(1440) {
        margin-right: 110px; }

      @include mq(1200) {
        margin: 9px 0 0; }

      @include mq(415) {
        margin: 15px 0 0; }

      img {
        width: 140px;
        @include mq(415) {
          width: 100px; } } }

    nav {
      float: left;

      @include mq(1200) {
        display: none; }

      ul {

        li {
          display: inline-block;
          margin-left: 75px;
          line-height: 65px;

          &.mobile-only {
            display: none; }

          a {
            display: block;
            color: $black-23;
            font-size: 14px;
            font-weight: 300; }

          &.sign-menu {
            a {
              display: inline-block; } }

          &.with-dd {

            &:hover:after {
                content: '';
                position: absolute;
                bottom: 10px;
                width: 45px;
                height: 1px;
                background: $red; }

            i {
              font-size: 19px;
              display: inline-block;
              margin-left: 9px; }

            .dd-wrap {
              transform: translateY(-60px);
              transition: all .2s linear;
              display: block;
              position: absolute;
              background: $black-23;
              width: 100%;
              left: 0;
              color: #fff;
              height: 56px;
              // transform: translateY(0)
              opacity: 0;
              z-index: -1;

              ul {
                max-width: 760px;
                margin-left: 380px;
                // border-bottom: 1px solid #ffffff
                padding: 0 100px;
                position: relative;
                display: flex;
                justify-content: space-around;

                @include mq(1440) {
                  margin-left: 180px; }

                @include mq(136) {
                  margin-left: 150px; }

                li {
                  font-size: 16px;
                  font-weight: 100;
                  line-height: 55px;

                  > a {
                    font-size: 16px;
                    font-weight: 100;
                    line-height: 55px;
                    color: #fff;

                    &:hover {
                      color: $red; } } } } }

            &:hover {

              .dd-wrap {
                display: block;
                position: absolute;
                background: $black-23;
                width: 100%;
                left: 0;
                color: #fff;
                height: 56px;
                transform: translateY(0);
                opacity: 1;
                z-index: -1 {



                  // .dd-holder
                  //   display: none

                  //   .dd-row
                  //     width: 535px
                  //     display: flex
                  //     justify-content: space-around

                  //     a
                  //       color: #fff
                  //       font-family: $gar-Italic
                  //       line-height: 40px
                  //       font-size: 18px
                  //       display: inline-block

                  // &:hover
                  //   color: $red

                  //   .dd-holder
                  //     display: none
                  //     position: absolute
                  //     left: 20px
                  //     top: 40px
 } } } } } } } }                  //     padding-top: 32px

  .header-right {
    float: right;
 }    // display: none
  .lang-bar {
    // float: right
    margin-right: 50px;
    display: none;

    @include mq(1200) {
      float: left; }

    @include mq(992) {
      margin-right: 30px; }


    p {
      display: inline-block;
      vertical-align: middle;
      line-height: 65px;
      font-size: 14px;

      &.disabled {
        &:hover {
          cursor: not-allowed; } }

      &.active {
        font-weight: 600;

        &:hover {
          cursor: pointer; } } } }

  .phone-number {
    position: absolute;
    right: 200px;
    line-height: 56px; } }
