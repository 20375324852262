// REFERENCE
.reference-wrap {
  padding-top: 100px;
  padding-bottom: 120px;


  @include mq(765) {
    padding: 70px 0 80px; }

  .page-title {
    font-family: $gar-Regular;
    font-size: 50px;
    position: relative;
    margin-bottom: 120px;
    text-align: center;

    @include mq(765) {
      font-size: 38px;
      margin-bottom: 80px; }

    &:after {
      content: '';
      position: absolute;
      bottom: -40px;
      left: 50%;
      width: 40px;
      height: 2px;
      background: $black-23;
      transform: translate(-50%,0); } }

  .title {
    margin-top: 20px;
    display: block;
    font-size: 32px;
    font-family: $gar-Regular; } }

// TECHNOLOGY
.tech-page, .about-page {
  .page-title {
    font-family: $gar-Regular;
    font-size: 50px;
    position: relative;
    margin-bottom: 50px;
    text-align: center;

    @include mq(765) {
      font-size: 38px;
      margin-bottom: 80px; } }

  .medium {
    line-height: 1.5;
    margin-bottom: 40px; }

  .three-boxes {
    @include clearfix;

    .img-holder {
      float: left;
      width: 33.33%;
      padding: 15px;

      @include mq(765) {
        width: 100%; }

      img {
        width: 100%; } }

    .box {
      float: left;
      width: 33.33%;
      text-align: center;
      margin: 70px 0 80px;
      position: relative;

      &:after {
        content: '';
        width: 2px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: #ccc; }

      &:last-child:after {
        display: none; }

      span {
        display: block;

        &.big {
          font-size: 35px;
          font-weight: $bold;
          margin-bottom: 15px;
          font-family: "Garamaond-Regular", sans-serif; }

        &.small {
          font-size: 18px; } } } } }


// ADDITIONAL

.half.images {
  .slick-list, .slick-track {
    height: 100%; }

  .slick-next {
    left: 0%;

    @include mq(765) {
      left: 50%; } }

  .slick-prev {
    right: 100%;

    @include mq(765) {
      right: 50%; } }

  @include mq(765) {
    height: auto !important; } }

.half.without-text {
  @include mq(765) {
    height: 280px !important; } }
