.sidr {
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 999999;
  width: 260px;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "lucida grande",tahoma,verdana,arial,sans-serif;
  font-size: 15px;
  background: #333;
  color: #fff;
  box-shadow: 0 0 5px 5px #222 inset;
  .sidr-inner {
    padding: 0 0 15px;
    > p {
      margin-left: 15px;
      margin-right: 15px; } }
  &.right {
    left: auto;
    right: -260px; }
  &.left {
    left: -260px;
    right: auto; }
  h1, h2, h3, h4, h5, h6 {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzRkNGQ0ZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzFhMWExYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #4d4d4d), color-stop(100%, #1a1a1a));
    background-image: -moz-linear-gradient(#4d4d4d, #1a1a1a);
    background-image: -webkit-linear-gradient(#4d4d4d, #1a1a1a);
    background-image: linear-gradient(#4d4d4d, #1a1a1a);
    font-size: 11px;
    font-weight: normal;
    padding: 0 15px;
    margin: 0 0 5px;
    color: #fff;
    line-height: 24px;
    box-shadow: 0 5px 5px 3px rgba(0, 0, 0, 0.2); }
  p {
    font-size: 13px;
    margin: 0 0 12px;
    a {
      color: rgba(255, 255, 255, 0.9); } }
  > p {
    margin-left: 15px;
    margin-right: 15px; }
  ul {
    display: block;
    margin: 0 0 15px;
    padding: 0;
    border-top: 1px solid #1a1a1a;
    border-bottom: 1px solid #4d4d4d;
    li {
      display: block;
      margin: 0;
      line-height: 48px;
      border-top: 1px solid #4d4d4d;
      border-bottom: 1px solid #1a1a1a;
      &:hover, &.active, &.sidr-class-active {
        border-top: 0;
        line-height: 49px; }
      &:hover > {
        a, span {
          box-shadow: 0 0 15px 3px #222 inset; } }
      &.active > {
        a, span {
          box-shadow: 0 0 15px 3px #222 inset; } }
      &.sidr-class-active > {
        a, span {
          box-shadow: 0 0 15px 3px #222 inset; } }
      a, span {
        padding: 0 15px;
        display: block;
        text-decoration: none;
        color: #fff; }
      ul {
        border-bottom: 0;
        margin: 0;
        li {
          line-height: 40px;
          font-size: 13px;
          &:last-child {
            border-bottom: 0; }
          &:hover, &.active, &.sidr-class-active {
            border-top: 0;
            line-height: 41px; }
          &:hover > {
            a, span {
              box-shadow: 0 0 15px 3px #222 inset; } }
          &.active > {
            a, span {
              box-shadow: 0 0 15px 3px #222 inset; } }
          &.sidr-class-active > {
            a, span {
              box-shadow: 0 0 15px 3px #222 inset; } }
          a, span {
            color: rgba(255, 255, 255, 0.8);
            padding-left: 30px; } } } } }
  form {
    margin: 0 15px; }
  label {
    font-size: 13px; }
  input {
    &[type="text"], &[type="password"], &[type="date"], &[type="datetime"], &[type="email"], &[type="number"], &[type="search"], &[type="tel"], &[type="time"], &[type="url"] {
      width: 100%;
      font-size: 13px;
      padding: 5px;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0 0 10px;
      border-radius: 2px;
      border: 0;
      background: rgba(0, 0, 0, 0.1);
      color: rgba(255, 255, 255, 0.6);
      display: block;
      clear: both; } }
  textarea, select {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 0 10px;
    border-radius: 2px;
    border: 0;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.6);
    display: block;
    clear: both; }
  input {
    &[type=checkbox] {
      width: auto;
      display: inline;
      clear: none; }
    &[type=button], &[type=submit] {
      color: #333;
      background: #fff; }
    &[type=button]:hover, &[type=submit]:hover {
      background: rgba(255, 255, 255, 0.9); } } }
