#burger {
	display: none;
	float: right;

	@include mq(1024) {
		display: block;
		color: $orange;
		font-size: 35px;
		margin-top: 15px; } }

.sidr {
	width: 100%;
	background: #232323;
	box-shadow: none;

	.sidr-inner {
		padding: 100px 15px 0; }

	&.left {
		left: -100%; }

	li {
		list-style-type: none;
		margin-bottom: 13px;
		padding-bottom: 13px;
		border-bottom: 1px solid #fff;

		&:last-child {
			border-bottom: none; }

		> {

			a {
				display: block;
				color: #fff;
				// font-family: $got-Medium
				font-size: 18px;
				padding-left: 67px; } } } }


.sidr-class-sidr-hidden {
	display: none; }

.sidr-class-desktop-only {
	display: none; }

.sidr-class-bg {
	background: #ffffff;
	position: absolute;
	top: 0;
	left: 0;
	height: 65px;
	width: 100%; }

.sidr-class-logo-holder {
	position: absolute;
	top: 15px;
	left: 15px;
	border-bottom: none !important;

	img {
		height: auto;
		width: 100px; }

	a {
		padding-left: 0 !important; } }

.sidr-class-menu-closer {
	position: absolute;
	top: 25px;
	right: 25px;
	border-bottom: none !important;

	a {
		font-family: $got-Bold !important;
		font-size: 24px !important;
		color: #232323 !important; } }

.sidr-class-profile {
	a {
		padding-left: 0 !important; }

	.sidr-class-img-holder {
		display: inline-block;
		vertical-align: middle;
		margin-right: 15px;
		border: 2px solid $orange;
		width: 47px;
		height: 47px;
		overflow: hidden;
		border-radius: 50%;
		position: relative;

		img {
			@include centered;
			max-width: 102%; } } }

.sidr-class-sign-in {
	border-bottom: none !important;

	.sidr-class-btn-holder {
		padding: 2px;
		height: 44px;
		@include orange-bg;
		margin-bottom: 20px;
		box-shadow: 0px 6px 11.76px 0.24px rgba(0, 0, 0, 0.25);
		width: 200px;
		margin: 0 auto 20px;

		a {
			width: 100%;
			height: 100%;
			background: #fff;
			border: none;
			font-size: 18px;
			font-family: $got-Bold;
			color: #fd2349;
			cursor: pointer;
			display: block;
			text-align: center;
			line-height: 42px; } }

	p {
		font-size: 16px;
		text-align: center;
		color: #232323;

		a {
 } } }			// color: $pink
