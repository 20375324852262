//General settings
body {
  background: #fff;
  font-family: 'Montserrat', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include letter-spacing(-25);
  color: $black-23;
  padding-top: 65px; }

.content {
  width: 1170px;
  padding: 0 15px;
  margin: 0 auto;
  @include clearfix;

  @include mq(1360) {
    width: 1100px; }

  @include mq(1280) {
    width: 960px; }

  @include mq(992) {
    width: 720px; }

  @include mq(765) {
    width: 100%;
    padding: 0 15px; } }


*:focus {
  otuline: none; }

button::-moz-focus-inner {
  border: 0; }

.sidebar {
  will-change: min-height; }

.sidebar__inner {
  transform: translate(0, 0);
  transform: translate3d(0, 0, 0);
  will-change: position, transform; }
