@import 'bourbon/bourbon';
@import 'bootstrap-sass-master/assets/stylesheets/bootstrap';
//@import  '../../../../node_modules/select2/src/scss/core'
@import  '../../../node_modules/slick-carousel/slick/slick.scss';
@import  '../../../node_modules/slick-carousel/slick/slick-theme.scss';

// $fa-font-path: 'fonts'
@import  '../../../node_modules/font-awesome/scss/font-awesome.scss';
//@import  '../../../node_modules/fullpage.js/dist/jquery.fullpage'
//@import  '../../../node_modules/fullpage.js/dist/jquery.fullpage.min.css'

