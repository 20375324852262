@font-face {
  font-family: 'Garamaond-Regular';
  src: url('../fonts/GaramondPremrPro.otf'); }

@font-face {
  font-family: 'Garamaond-BoldItalic';
  src: url('../fonts/AGaramondPro-BoldItalic.otf'); }

@font-face {
  font-family: 'Garamaond-Italic';
  src: url('../fonts/AGaramondPro-Italic.otf'); }

@font-face {
  font-family: 'Gotham-Book';
  src: url('../fonts/Gotham-Book.otf'); }

@font-face {
  font-family: 'Gotham-Black';
  src: url('../fonts/Gotham-Black.otf'); }

@font-face {
  font-family: 'Gotham-Bold';
  src: url('../fonts/Gotham-Bold.otf'); }

@font-face {
  font-family: 'Gotham-Medium';
  src: url('../fonts/Gotham-Medium.otf'); }

@font-face {
  font-family: 'Gotham-Light';
  src: url('../fonts/Gotham-Light.otf'); }
