// Font family
$gar-Regular: 'Garamaond-Regular', sans-serif;
$gar-BoldItalic: 'Garamaond-BoldItalic', sans-serif;
$gar-Italic: 'Garamaond-Italic', sans-serif;
$got-Book: 'Gotham-Book', sans-serif;
$got-Black: 'Gotham-Black', sans-serif;
$got-Bold: 'Gotham-Bold', sans-serif;
$got-Medium: 'Gotham-Medium', sans-serif;
$got-Light: 'Gotham-Light', sans-serif;

// Colors
$black-23: #232323;
$orange: #fe5041;
$red: #f83333;

// Fonts weights
$light: 300;
$regular: 400;
$medium: 500;
$bold: 600;

// Text shadow
$menu-text-shadow: 0px 2px 3.96px rgba(0, 0, 0, 0.05);
