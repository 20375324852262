///////////
//Homepage
//////////
.home-hero {
  overflow: hidden;

  .hero-wrap {
    position: relative;
    height: calc(100vh - 65px);

    img {
      width: 100%;

      @include mq(1440) {
        object-fit: cover;
        max-height: 100%;
        width: auto;
        min-width: 100%; } }

    .slick-track, .slick-slider, .slick-list {
      height: 100%; }


    .slick-dots {
      bottom: 60px;

      @include mq(415) {
        bottom: 30px; }

      li {

        button {
          border: 1px solid #fff;
          border-radius: 50%;

          &:before {
            content: '';
            display: none; } }

        &.slick-active {
          button {
            background: #fff; } } } }

    .hero-slide {
      color: #fff;
      position: relative;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;


      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        background: rgba(0, 0, 0, 0.25);
        top: 0;
        left: 0; }

      .text-wrap {
        @include centered;
        z-index: 11;
        color: #fff;
        text-align: center;

        p {
          font-size: 24px;
          font-weight: $regular;
          text-transform: uppercase;
          margin-bottom: 80px;
          position: relative;

          @include mq(415) {
            font-size: 22px;
            margin-bottom: 60px; }


          &:after {
            content: '';
            width: 40px;
            height: 2px;
            background: #fff;
            position: absolute;
            bottom: -50px;
            left: 50%;
            transform: translate(-50%,0);
            display: block;

            @include mq(415) {
              bottom: -20px; } } }

        h3 {
          font-size: 60px;
          margin-bottom: 25px;
          font-family: $gar-Regular;

          @include mq(765) {
            font-size: 50px; }

          @include mq(415) {
            font-size: 40px; } }

        span {
          margin-bottom: 80px;
          display: block;
          font-family: $gar-Italic;
          font-size: 24px;

          @include mq(415) {
            margin-bottom: 60px;
            font-size: 22px; } }



        a {
          display: block;
          width: 180px;
          height: 50px;
          border: 2px solid #fff;
          line-height: 48px;
          text-align: center;
          font-size: 16px;
          font-weight: $regular;
          transition: all .2s linear;
          margin: 0 auto;

          &:hover {
            background: #fff;
            color: $black-23; } } } } } }

// ABOUT HOMEPAGE
.home-about-wrap {
  padding: 120px 0 240px;

  @include mq(1200) {
    padding: 100px 0 120px; }

  @include mq(992) {
    padding: 70px 0 90px; }

  .home-about-content {
    width: 1340px;
    margin: 0 auto;
    @include clearfix;

    @include mq(1440) {
      width: 1100px; }

    @include mq(1280) {
      width: 960px; }

    @include mq(992) {
      width: 720px; }

    @include mq(765) {
      width: 100%;
      padding: 0 15px; }

    &.project-single-img {
      // .img-holder
      //   float: left
      //   width: 50%
      //   margin: 0

      //   img
      //     margin: 0 auto

      // .text-holder
      //   float: left
      //   width: 50%
      .img-holder {
        margin-top: 0; } }



    .img-holder {
      float: left;
      width: 470px;
      margin-top: 155px;
      margin-right: 105px;

      img {
        width: 100%; }

      @include mq(1440) {
        width: 350px;
        margin-top: 85px;
        margin-right: 85px; }

      @include mq(1360) {
        width: 320px;
        margin-top: 85px;
        margin-right: 65px; }

      @include mq(992) {
        margin: 0 auto 40px;
        float: none; }

      @include mq(415) {
        width: 90%; } }


    .text-holder {
      float: left;
      width: 730px;

      @include mq(1440) {
        width: 640px; }

      @include mq(1360) {
        width: 570px; }

      @include mq(992) {
        width: 100%;
        text-align: center;
        float: none; }

      .title {
        font-family: $gar-Regular;
        font-size: 102px;
        color: #232323;
        margin-bottom: 70px;

        @include mq(1440) {
          font-size: 72px; }

        @include mq(1200) {
          font-size: 62px;
          margin-bottom: 50px; } }

      .big {
        font-family: $gar-Regular;
        font-style: italic;
        font-size: 38px;
        letter-spacing: -0.025em;
        margin-bottom: 50px;
        line-height: 1.3;

        @include mq(1440) {
          font-size: 28px; }

        @include mq(1200) {
          font-size: 26px;
          margin-bottom: 40px; } }


      .small {
        padding-left: 100px;
        position: relative;
        font-size: 16px;
        letter-spacing: -0.025em;
        line-height: 1.4;

        @include mq(1200) {
          padding-left: 70px; }

        @include mq(992) {
          padding-left: 0; }


        &:after {
          position: absolute;
          content: '';
          left: 50px;
          top: 0;
          width: 1px;
          height: 100%;
          background: #232323;

          @include mq(1200) {
            left: 25px; }

          @include mq(992) {
            display: none; } } } } } }

// HOME SLIDER
.home-slider-wrap {
  background: #f8f8f8;
  padding: 160px 0;

  @include mq(1200) {
    padding: 100px 0 120px; }

  @include mq(992) {
    padding: 70px 0 90px; }

  .slider {
    @include clearfix;

    .slide {
      @include clearfix; }

    .half {
      width: 50%;
      float: left;
      height: 700px;

      @include mq(1200) {
        height: 640px; }

      @include mq(765) {
        float: none;
        width: 100%; }

      @include mq(480) {
        height: 400px;

        img {
          height: auto !important; } }

      &.with-text {
        background: #fff;
        padding: 110px 120px 0;
        position: relative;
        text-align: center;

        @include mq(1360) {
          padding: 110px 60px 0; }

        @include mq(1200) {
          padding: 40px 50px 0; }

        @include mq(992) {
          padding: 30px 30px 0; } }

      .top {
        font-size: 18px;
        letter-spacing: 0.2em;
        position: relative;
        margin-bottom: 80px;

        &:after {
          content: '';
          position: absolute;
          bottom: -40px;
          left: 50%;
          width: 40px;
          height: 2px;
          background: $black-23;
          transform: translate(-50%,0); } }

      .title {
        font-size: 40px;
        font-family: $gar-Regular;
        margin-bottom: 15px; }

      .place {
        font-family: $gar-Italic;
        font-size: 18px;
        margin-bottom: 65px; }

      .about {
        font-family: $gar-Regular;
        font-size: 18px;
        height: 150px;
        margin-bottom: 130px;

        @include mq(992) {
          margin-bottom: 80px; } }

      .slider-btn {
        display: block;
        width: 180px;
        height: 50px;
        line-height: 50px;
        @include red-gradient;
        color: #ffffff;
        font-size: 16px;
        text-align: center;
        font-weight: $regular;
        margin: 0 auto;
        @include inner-input-shadow; }

      .slider-no {
        font-family: $gar-Regular;
        position: absolute;
        bottom: 20px;
        left: 80px;

        @include mq(480) {
          left: 50%;
          transform: translate(-50%, 0); } } } }

  .slick-next, .slick-prev {
    width: 50px;
    height: 50px;
    background: $black-23;
    bottom: -25px;
    top: initial;
    left: initial;
    right: initial;
    z-index: 11;
    color: #fff; }

  .slick-next {
    left: 50%;

    &:before {
      font-family: FontAwesome;
      content: '\f105';
      font-size: 40px; } }

  .slick-prev {
    right: 50%;

    &:before {
      font-family: FontAwesome;
      content: '\f104';
      font-size: 40px; } } }

// PROFF
.home-proff {
  padding: 160px 0 180px;

  @include mq(1200) {
    padding: 100px 0 120px; }

  @include mq(992) {
    padding: 70px 0 90px; }

  .title {
    font-family: $gar-Regular;
    font-size: 38px;
    text-align: center;
    margin-bottom: 200px;

    @include mq(1200) {
      margin-bottom: 120px; } }

  .box-row {
    @include clearfix;
    margin-bottom: 110px;

    &.img-right {
      .img-holder {
        float: right;

        @include mq(765) {
          float: none; } } }

    .img-holder {
      float: left;
      width: 50%;

      @include mq(1200) {
        width: 40%; }

      @include mq(992) {
        width: 30%; }

      @include mq(765) {
        width: 260px;
        margin: 0 auto 30px;
        float: none; }

      img {
        width: 100%; } }

    .text-holder {
      float: left;
      width: 50%;
      padding: 35px 60px 0 50px;

      @include mq(1200) {
        width: 60%;
        padding: 10px 50px 0 40px; }

      @include mq(992) {
        width: 70%;
        padding: 10px 40px 0; }

      @include mq(765) {
        width: 100%;
        padding: 10px;
        float: none; }

      .box-title {
        font-size: 24px;
        letter-spacing: 0.2em;
        margin-bottom: 90px;
        position: relative;

        @include mq(992) {
          font-size: 22px;
          margin-bottom: 50px; }

        &:after {
          content: '';
          position: absolute;
          bottom: -40px;
          left: 0;
          width: 40px;
          height: 2px;
          background: $black-23;

          @include mq(992) {
            bottom: -20px; } } }

      p.text {
        font-size: 18px;

        @include mq(992) {
          font-size: 16px; } } } } }

// PDF DOWNLOAD
.dl-pdf {
  @include clearfix;
  background: #f8f8f8;

  .pdf-img {
    float: left;
    width: 50%;

    @include mq(1200) {
      width: 40%; }

    @include mq(992) {
      width: 340px;
      margin: 0 auto 30px;
      float: none; }

    @include mq(415) {
      width: 100%; }

    img {
      width: 100%; } }

  .pdf-text {
    padding-top: 180px;
    float: left;
    width: 50%;
    text-align: center;

    @include mq(1440) {
      padding-top: 60px; }

    @include mq(1200) {
      width: 60%;
      padding-top: 40px; }

    @include mq(992) {
      width: 100%;
      float: none;
      padding-top: 0;
      padding-bottom: 40px; }

    .title {
      font-family: $gar-Regular;
      font-size: 38px;
      margin-bottom: 120px;

      @include mq(1360) {
        margin-bottom: 60px; }

      @include mq(1200) {
        margin-bottom: 40px;
        font-size: 32px; }

      @include mq(765) {
        padding: 0 20px; } }

    .dl-btn {
      display: inline-block;
      margin-bottom: 110px;

      @include mq(1200) {
        margin-bottom: 80px; }

      img {
        margin: 0 auto 35px;

        @include mq(1200) {
          margin: 0 auto 10px;
          width: 30px; } }

      p {
        font-family: $got-Book;
        font-size: 24px;

        @include mq(1200) {
          font-size: 20px; } } }

    .expl {
      font-family: $gar-Italic;
      font-size: 24px;
      position: relative;

      @include mq(1200) {
        font-size: 20px; }

      @include mq(765) {
        padding: 0 20px; }

      &:after {
        content: '';
        position: absolute;
        top: -40px;
        left: 50%;
        width: 40px;
        height: 2px;
        background: $black-23;
        transform: translate(-50%,0); } } } }

