@-webkit-keyframes moveY {
  0% {
    -webkit-transform: translateY(3px);
    transform: translateY(3px); }

  30% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px); }

  100% {
    -webkit-transform: translateY(3px);
    transform: translateY(3px); } }


@keyframes moveY {
  0% {
    -webkit-transform: translateY(3px);
    transform: translateY(3px); }

  30% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px); }

  100% {
    -webkit-transform: translateY(3px);
    transform: translateY(3px); } }

.scroll-down {
  a {
    // -webkit-transform: translateY(-3px)
    // -ms-transform: translateY(-3px)
    // transform: translateY(-3px)
    -webkit-animation: moveY 3s infinite;
    animation: moveY 3s infinite;
    position: absolute;
    @include centered;
    top: 100%; } }

@-webkit-keyframes pulse_pause {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }

  14% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02); }

  18% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98); }

  20% {
    -webkit-transform: scale(1);
    transform: scale(1); }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }


@keyframes pulse_pause {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }

  14% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02); }

  18% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98); }

  20% {
    -webkit-transform: scale(1);
    transform: scale(1); }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.home-hero {}
.dl-btn-holder {
    -webkit-animation: pulse_pause 3s infinite;
    animation: pulse_pause 3s infinite;
    -webkit-transition-delay: .1s;
    -o-transition-delay: .1s;
    transition-delay: .1s;

    transition: opacity 1s cubic-bezier(.165,.84,.44,1),transform 1s cubic-bezier(.165,.84,.44,1),-webkit-transform 1s cubic-bezier(.165,.84,.44,1);
    -webkit-transition: -webkit-transform .2s cubic-bezier(.165,.84,.44,1),-webkit-box-shadow .2s;
    display: inline-block;

    .dl-btn {

        transition: -webkit-transform .2s cubic-bezier(.165,.84,.44,1),-webkit-box-shadow .2s;

        -o-transition: transform .2s cubic-bezier(.165,.84,.44,1),box-shadow .2s;

        transition: transform .2s cubic-bezier(.165,.84,.44,1),box-shadow .2s;

        transition: transform .2s cubic-bezier(.165,.84,.44,1),box-shadow .2s,-webkit-transform .2s cubic-bezier(.165,.84,.44,1),-webkit-box-shadow .2s;
        -webkit-animation-delay: .15s;
        animation-delay: .15s;
        will-change: transform;
        // box-shadow: 0 4px 10px 0 rgba(0,0,0,.25)


        &:hover {
            transform: scale(1.2) !important;
 } } }            // box-shadow: 0 8px 25px 0 rgba(0,0,0,.25)
