// MODALS
.modal-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &.modal-hidden {
    display: none; }

  &.modal-visible {
    display: block; }

  .modal-content {
    @include centered;
    background: #fff; } }
