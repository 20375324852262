// CATEGORY
.category-wrap {
  padding-top: 100px;
  padding-bottom: 120px;

  @include mq(765) {
    padding: 70px 0 80px; }

  .page-title {
    font-family: $gar-Regular;
    font-size: 50px;
    position: relative;
    margin-bottom: 120px;
    text-align: center;

    @include mq(765) {
      font-size: 38px;
      margin-bottom: 80px; }


    &:after {
      content: '';
      position: absolute;
      bottom: -40px;
      left: 50%;
      width: 40px;
      height: 2px;
      background: $black-23;
      transform: translate(-50%,0);

      @include mq(765) {
        bottom: -30px; } } }

  .talls-holder {
    @include clearfix;

    @include mq(765) {
      margin: 0 -5px; }

    a {
      display: block;
      width: 33.33%;
      float: left;
      padding: 0 15px;
      margin-bottom: 50px;

      @include mq(765) {
        padding: 0 8px;
        width: 100%; }

      &:hover {
        cursor: pointer;

        .img-holder {
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.35); }

        p {
          text-decoration: underline; } }

      .img-holder {
        position: relative;
        height: 500px;
        overflow: hidden;
        margin-bottom: 35px;
        transition: all .2s linear;

        @include mq(992) {
          height: 370px; }

        @include mq(765) {
          height: 400px; }

        img {
          @include centered; } }

      p {
        text-align: center;
        font-family: $gar-Italic;
        font-size: 24px;
        min-height: 48px; } } } }

.icons-symbols {
  text-align: center;
  margin-bottom: 20px;

  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    position: relative;
    margin: 0 10px;

    img {
      max-width: 100%;
      position: absolute;

      &.color-icon {
        opacity: 0;
        transition: all .2s linear; }

      &:hover {
        &.color-icon {
          opacity: 1; }

        &.black-icon {
          opacity: 0; } } } } }
