// LOCATIONS
.locations {
  padding: 100px 0;
  color: #ffffff;
  background: #232323;

  @include mq(992) {
    padding: 70px 0 90px; }

  .halves {
    @include clearfix;

    .half {
      width: 50%;
      float: left;
      padding: 0 30px;

      @include mq(765) {
        width: 100%;
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0; } }

      .subtitle {
        font-size: 20px;
        margin-bottom: 15px; }

      p {
        font-size: 16px;
        line-height: 1.5; }

      .map-holder {
        margin-top: 15px; } } }

  .title {
    font-family: $gar-Regular;
    font-size: 38px;
    margin-bottom: 120px;
    position: relative;

    @include mq(992) {
      margin-bottom: 100px; }

    @include mq(765) {
      margin-bottom: 80px; }

    &:after {
      content: '';
      position: absolute;
      bottom: -40px;
      left: 0;
      width: 40px;
      height: 2px;
      background: #fff; } }

  .map-holder {
    img {
      width: 100%; } }

  .loc-boxes {
    @include clearfix;
    margin-bottom: 40px;

    .box {
      float: left;
      width: 33.33%;
      border-right: 2px solid #ffffff;
      padding: 15px 0;

      @include mq(765) {
        float: none;
        width: 100%;
        margin-bottom: 20px;
        border-right: 0; }

      &:last-child {
        border-right: 0; }

      p {
        text-align: center;
        font-size: 18px;
        font-weight: 100;

        &:first-child {
          margin-bottom: 15px; } } } }


 }  //Footer
footer {
  background: #303030;
  border-top: 1px solid #fff;
  padding: 65px 0 80px;
  color: #ffffff;



  .subtitle {}


  @include mq(992) {
    padding: 50px 0 20px; }

  .content {
    @include clearfix;

    .contact-box {
      margin-right: 65px; }

    .newsletter {
      visibility: hidden;

      @include mq(480) {
        display: none; } }

    .contact-box, .newsletter {
      float: left;

      @include mq(992) {
        float: none;
        margin-bottom: 60px; }

      .title {
        font-family: $gar-Regular;
        font-size: 38px;
        margin-bottom: 90px;
        position: relative;

        @include mq(992) {
          margin-bottom: 40px;
          font-size: 30px; }

        &:after {
          content: '';
          position: absolute;
          bottom: -40px;
          left: 0;
          width: 40px;
          height: 2px;
          background: #fff;

          @include mq(992) {
            bottom: -20px; } } } } }

  .newsletter {

    .title {
      margin-bottom: 75px !important;

      @include mq(992) {
        margin-bottom: 40px !important; } }

    input {
      width: 385px;
      height: 50px;
      border: 2px solid #ffffff;
      background: transparent;
      color: #fff;
      padding: 0 20px;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;

      @include mq(1360) {
        width: 285px; } } }

  .social-icons {



    a {
      display: inline-block;
      margin-right: 30px;
      font-size: 24px; } }

  .copyright {
    float: right;
    padding-top: 70px;

    @include mq(992) {
      float: none;
      padding-top: 30px; }

    p {
      text-align: right;
      font-size: 16px;
      margin-bottom: 15px;

      a {
        @include mq(992) {
          display: inline; } }

      @include mq(992) {
        text-align: center;
        font-size: 14px; }

      &:first-child {
        position: relative;
        padding-right: 50px;

        @include mq(992) {
          padding-right: 0; }

        &:after {
          content: '';
          position: absolute;
          right: 0;
          width: 40px;
          height: 2px;
          background: #fff;
          top: 45%;

          @include mq(992) {
            display: none; } } }


      img {
        display: inline-block;
        margin-left: 5px;

        @include mq(992) {
          display: none; } } } } }
