// CONTACT
.contact-wrap {
  width: 100%;
  height: calc(100vh);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  background: $black-23;

  @include mq(480) {
    height: calc(100vh - 65px); }

  .contact-holder {
    @include centered;
    color: #fff;
    text-align: center;
    margin-bottom: 100px;
    white-space: nowrap;

    .title {
      font-family: $gar-Regular;
      font-size: 50px;
      position: relative;
      margin-bottom: 120px;

      @include mq(415) {
        margin-bottom: 90px; }

      &:after {
        content: '';
        position: absolute;
        bottom: -40px;
        left: 50%;
        width: 40px;
        height: 2px;
        background: #fff;
        transform: translate(-50%,0); } }

    .box {
      margin-bottom: 120px;
      position: relative;

      @include mq(415) {
        margin-bottom: 90px; }

      &:after {
        content: '';
        position: absolute;
        bottom: -20px;
        left: 50%;
        width: 40px;
        height: 1px;
        background: #fff;
        transform: translate(-50%,0); }
      p {
        font-size: 18px;
        margin-bottom: 15px; } }


    .contact {
      p {
        font-family: $gar-Italic;
        font-size: 40px;
        margin-bottom: 20px;

        @include mq(415) {
          font-size: 32px; } } } } }
