//responsive media queries
@mixin mq($width) {
  @if $width == 1440 {
    @media only screen and (max-width: 1440px) {
      @content; } }
  @else if $width == 1800 {
    @media only screen and (max-width: 1800px) {
      @content; } }
  @else if $width == 1366 {
    @media only screen and (max-width: 1366px) {
      @content; } }
  @else if $width == 1360 {
    @media only screen and (max-width: 1360px) {
      @content; } }
  @else if $width == 1280 {
    @media only screen and (max-width: 1280px) {
      @content; } }
  @else if $width == 1200 {
    @media only screen and (max-width: 1200px) {
      @content; } }
  @else if $width == 1120 {
    @media only screen and (max-width: 1120px) {
      @content; } }
  @else if $width == 1024 {
    @media only screen and (max-width: 1024px) {
      @content; } }
  @else if $width == 992 {
    @media only screen and (max-width: 992px) {
      @content; } }
  @else if $width == 768 {
    @media only screen and (max-width: 768px) {
      @content; } }
  @else if $width == 765 {
    @media only screen and (max-width: 765px) {
      @content; } }
  @else if $width == 635 {
    @media only screen and (max-width: 635px) {
      @content; } }
  @else if $width == 540 {
    @media only screen and (max-width: 540px) {
      @content; } }
  @else if $width == 480 {
    @media only screen and (max-width: 480px) {
      @content; } }
  @else if $width == 415 {
    @media only screen and (max-width: 415px) {
      @content; } }
  @else if $width == 360 {
    @media only screen and (max-width: 360px) {
      @content; } }
  @else if $width == 320 {
    @media only screen and (max-width: 320px) {
      @content; } } }

//clearfix
@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table; }

  &:after {
    clear: both; } }

@mixin letter-spacing($tracking) {
  letter-spacing: $tracking / 1000 * 1em; }

//placeholder styling
@mixin inputPlaceholder {
  $selector: "";
  $prefixes: (moz: "::-moz", webkit: "::-webkit", ie: ":-ms");

  @each $prop, $value in $prefixes {
    @if $prop != "moz" {
      $selector: #{$value}-input-placeholder; }
    @else {
      $selector: #{$value}-placeholder; }

    @if & {
      &#{$selector} {
        @content; } }
    @else {
      #{$selector} {
        @content; } } }

  &::placeholder {
    @content; } }

// center item
@mixin centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%); }


//box shadow
@mixin box-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2); }

@mixin box-shadow-hover {
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2); }


//underline hover
@mixin underline-hover {

  &.active:before {
    content: '';
    display: block;
    position: absolute;
    width: 50%;
    height: 2px;
    bottom: 10px;
    left: 25%;
    background-color: #000;
    visibility: visible;
    transform: scaleX(1);

    @include mq(480) {
      bottom: 7px; } }

  &:hover {
    color: #000; }

  &:before {
    content: '';
    position: absolute;
    width: 50%;
    height: 2px;
    bottom: 10px;
    left: 25%;
    background-color: #000;
    visibility: hidden;
    transform: scaleX(0);
    transition: all .3s ease-in-out 0s;
    @include mq(480) {
      bottom: 7px; } }


  &:hover:before {
    visibility: visible;
    transform: scaleX(1); } }

@mixin checked {
  width: 10px;
  height: 10px;
  background: #000;
  border-radius: 100%;
  @include position(absolute, 50% null null 50%);
  @include transform(translate(-50%,-50%)); }

@mixin input-shadow {
  -webkit-box-shadow: 3px 3px 0 0 rgba(204,204,204,1);
  -moz-box-shadow: 3px 3px 0 0 rgba(204,204,204,1);
  box-shadow: 3px 3px 0 0 rgba(204,204,204,1); }

@mixin input-hover {
  -webkit-box-shadow: 4px 4px 4px 1px rgba(204,204,204,1);
  -moz-box-shadow: 4px 4px 4px 1px rgba(204,204,204,1);
  box-shadow: 4px 4px 4px 1px rgba(204,204,204,1); }

@mixin home-block {
  padding: 180px 0 130px;

  h2 {
    font-size: 50px;
    font-family: $got-Black; }

  p {
    font-family: $got-Book;
    letter-spacing: 0.0025em;

    &.big {
      font-size: 17px;
      margin-bottom: 15px; }

    &.small {
      font-size: 12px;
      margin-bottom: 50px; } } }

@mixin orange-bg {
  background: #ff7236;
  background: -moz-linear-gradient(left, #ff7236 0%, #fd2349 100%);
  background: -webkit-linear-gradient(left, #ff7236 0%,#fd2349 100%);
  background: linear-gradient(to right, #ff7236 0%,#fd2349 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7236', endColorstr='#fd2349',GradientType=1 ); }

@mixin inner-input-shadow {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15); }

@mixin red-gradient {
  background: #f84444;
  background: -moz-linear-gradient(left, #f84444 0%, #f83333 100%);
  background: -webkit-linear-gradient(left, #f84444 0%,#f83333 100%);
  background: linear-gradient(to right, #f84444 0%,#f83333 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f84444', endColorstr='#f83333',GradientType=1 ); }
