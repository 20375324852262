// PROJECT
.project-single-img {
  @include clearfix; }

.inner-page {
  .hero-slide {
    height: 100%; }

  // .img-holder
  //   width: 50%
  //   float: left
  //   margin: 0 auto

  // .text-holder
  //   width: 50%
 }  //   float: left
